import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

// images
import facebook from '../../assets/facebook-v2.svg';
import twitter from '../../assets/twitter-v2.svg';
import icon from '../../assets/icon.svg';
import instagram from '../../assets/instagram-v2.svg';
import linkedin from '../../assets/linkedin-v2.svg';

function Footer() {
  return (
    <footer className="blinx__footer">
        <div className="blinx__footer-container">
            <div className="blinx__footer-top">
                <div className="blinx__footer-row d-flex gap-2 justify-between">
                    <div className="blinx__footer-col -left">
                        <div className="blinx__footer-logo">
                            <img src={icon} alt="Blinx logo" loading='eager' />
                        </div>
                        <div className="blinx__footer-text">
                            <h3> Automate your finance </h3>
                        </div>
                    </div>

                    <div className="blinx__footer-col -right">
                        <div className="blinx__footer-navs">
                            <Link href="#">
                                <span> Build with us </span>
                            </Link>
                            <Link href="/faqs">
                                <span> FAQs </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blinx__footer-bottom">
                <div className="blinx__footer-row d-flex item-center gap-2 justify-between">
                    <div className="blinx__footer-col blinx__footer-col -left d-flex item-center gap-3">
                        <div className="d-flex item-center gap-3">
                            <Link href="#" className="blinx__footer-link">
                                <span> Privacy </span>
                            </Link>
                            <Link href="#" className="blinx__footer-link">
                                <span> Terms </span>
                            </Link>
                        </div>
                        <a 
                            href="mailto:hello@blinx.com" 
                            className="blinx__footer-link blinx__footer-mail" 
                            id="mail"
                        >
                            <span> Hello@blinx.com </span>
                        </a>
                    </div>

                    <div className="blinx__footer-col blinx__footer-col -right d-flex item-center gap-3">
                        <a href="https://twitter.com/blinxhq?s=21&t=MtdmscuNMRUnnprczwkoWA" target="_blank" rel="noreferrer">
                            <img src={twitter} alt="Blinx twitter" className="blinx__footer-icon" />
                        </a>
                        <a href="https://www.instagram.com/blinx_hq/" target="_blank" rel="noreferrer">
                            <img src={instagram} alt="Blinx instagram" className="blinx__footer-icon" />
                        </a>
                        <a href="https://www.linkedin.com/company/blinxhq/" target="_blank" rel="noreferrer">
                            <img src={linkedin} alt="Blinx linkedin" className="blinx__footer-icon" />
                        </a>
                        <a href="https://www.facebook.com/people/Blinxhq/100090028208846/" target="_blank" rel="noreferrer">
                            <img src={facebook} alt="Blinx Facebook" className="blinx__footer-icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer;