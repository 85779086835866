
import profile_1 from '../assets/profile-1.png';

const faqs = [
    { 
        question: "What is your fintech startup all about?",
        answer: "Our fintech startup focuses on making it easy for people in the diaspora to pay their bills back home and send money to Nigeria. We also offer a dollar card that can be used for international purchases and services.",
    },
    { 
        question: "How does your bill scheduling and automation service work?",
        answer: "Our bill scheduling and automation service allows you to set up recurring payments for bills back home, so you don't have to worry about remembering to pay them each month. You can set up the payments through our platform, and we'll take care of the rest.",
    },
    { 
        question: "What kinds of bills can I pay through your platform?",
        answer: "We currently support a wide range of bills, including electricity, water, internet, and more. If you have a bill that you want to pay but don't see it on our platform, please let us know and we'll do our best to add it.",
    },
    { 
        question: "How do I send money to Nigeria through your platform",
        answer: "To send money to Nigeria, you can use our platform to transfer funds directly to a recipient's bank account. You'll need to provide some information about the recipient and their bank account, and we'll take care of the rest. Our transfer fees are competitive, and our exchange rates are updated in real time.",
    },
    { 
        question: "What is your dollar card, and how does it work",
        answer: "Our dollar card is a prepaid card that can be used for international purchases and services. You can load funds onto the card through our platform, and then use it just like any other debit card. The card is accepted at millions of merchants worldwide, and there are no foreign transaction fees.",
    },
    { 
        question: "How do I sign up for your service?",
        answer: "To sign up for our service, simply visit our website and create an account. You'll need to provide some basic information about yourself, and then you can start using our platform to schedule bill payments, send money to Nigeria, and fund your dollar card.",
    },
    { 
        question: "Is my information secure with your platform?",
        answer: "Yes, we take security very seriously, and we use industry-standard encryption to protect your information. We also follow best practices for data privacy, and we will never share your information with third parties without your consent",
    },
    { 
        question: "What kind of customer support do you offer?",
        answer: "We offer customer support through a variety of channels, including email, phone, and chat. Our support team is available 24/7 to help with any questions or issues you may have.",
    },
]
const steps = [];
const testimonies = [
    {
        name: "Jane brandon",
        role: "Chief of product - Lex app",
        testimony: "The Blinx app does wonders for my finance as i am able to completely manage my bills and finance",
        img_url: profile_1
    }
];

export {
    faqs,
    steps,
    testimonies
}
