import React, { useRef } from 'react';
import './layout.css';
import { Outlet } from 'react-router-dom';
import { CTA, Footer, Navbar } from '../../components';

const Layout = () => {
    const waitlist_ref = useRef(null);

    const scroll_to_waitlist = () => {
        waitlist_ref.current.scrollIntoView({
            behavior: "smooth"
        });
    }
    return (
        <div className="blinx__layout">
            <div className="blinx__layout-body">
                <div className="blinx__layout-nav">
                    <Navbar 
                        handle_click={scroll_to_waitlist}
                    />
                </div>
                <main className="blinx__layout-main">
                    <Outlet />
                </main>
                <CTA waitlist_ref={waitlist_ref}/>
            </div>
            <div className="blinx__layout-footer">
                <Footer />
            </div>
        </div>
    )
}

export default Layout;