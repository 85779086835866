import React, { useEffect, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { Features } from "../containers";
import { BuiltWith, Cursor, Header, Oppurtunity} from "../components";
import { useLocation } from "react-router-dom";

const Homepage = () => {
    const { hash } = useLocation();

    gsap.registerPlugin(ScrollTrigger);

    useLayoutEffect(()=> {
        document.body.addEventListener("mousemove", evt=> {
            const mouseX = evt.clientX;
            const mouseY = evt.clientY;
      
            gsap.set(".blinx__hero-cursor", {
                x: mouseX,
                y: mouseY
            })
      
            gsap.to(".blinx__hero-shape", {
                x: mouseX,
                y: mouseY,
                stagger: -0.1
            })
        })
    }, [])

    const features_ref = useRef(null);
    useEffect(()=> {
        if (hash === '#why-blinx') {
            features_ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [hash]);
    return (
        <>
            <Cursor />
            <Header />
            <Features features_ref={features_ref} />
            <BuiltWith />
            <Oppurtunity />
        </>
    )
}

export default Homepage;