import React from "react";
import './header.css';

import blinx_app from '../../assets/app.svg';
import Particles from "../particles/Particles";

const Header = () => {
    return (
        <header className="blinx__hero">
            <div className="blinx__hero-particles w-100 h-100">
                <Particles />
            </div>

            <div className="blinx__hero-content">
                <div className="blinx__hero-head d-flex item-center justify-center">
                    <div className="blinx__hero-shapes">
                        <div className="blinx__hero-shape -shape1" />
                        <div className="blinx__hero-shape -shape2" />
                        <div className="blinx__hero-shape -shape3" />
                    </div>

                    <div className="blinx__hero-head_box d-flex item-center justify-center">
                        <div className="blinx__hero-head_text">
                            <h1> Finance <br /> Reimagined </h1>
                        </div>
                    </div>
                </div>

                <div className="blinx__hero-body">
                    <div className="blinx__hero-container d-flex item-center justify-center">
                        <div className="blinx__hero-img">
                            <img 
                                src={blinx_app} 
                                alt="Blinx App" 
                                id="phone"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;