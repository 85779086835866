import { FAQ } from "../containers";

const Faq = () => {
    return (
        <>
            <FAQ />
        </>
    )
}

export default Faq;