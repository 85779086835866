import React from "react";
import "./feature.css";

const Feature = ({ color, bgColor, img, subtitle, title }) => {
    return (
        <div 
            className="blinx__feature-row d-flex item-center gap-2 justify-center" 
            style={{ backgroundColor: bgColor || "#E7FCFF", color: color || "fff"}}
        >
            <div className="blinx__feature-col -left">
                <div className="-feature_textbox">
                    <h3 className="-feature_title"> { title } </h3>
                    <p className="-feature_subtitle"> { subtitle } </p>
                </div>
            </div>
            <div className="blinx__feature-col -right">
                <div className="-feature_img-container">
                    { img && <img src={img} alt="" /> }
                </div>
            </div>
        </div>
    )
}

export default Feature;