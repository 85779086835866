import React, { useRef, useState } from "react";
import './faq.css';

import { Accordion } from "../../components";
import blinx_logo from '../../assets/icon.svg';
import scroller from '../../assets/scroller.svg';
import { faqs } from '../../data/data';

const FAQ = () => {
    const [active, setActive] = useState(0);
    const scroll_ref = useRef(null);

    const handle_scroll = () => {
        scroll_ref.current.scrollIntoView({
            behaviour: "smooth"
        })
    }

    return (
        <div className="blinx__faq">
            <div className="blinx__faq-hero">
                <div className="blinx__faq-btns scroller">
                    <button onClick={handle_scroll}>
                        <img src={scroller} alt="" />
                    </button>
                </div>
                <div className="blinx__faq-btns"></div>
                <div className="blinx__faq-hero_container">
                    <div className="blinx__faq-hero_text">
                        <h1> Frequently </h1>
                        <div>
                            <h1> Asked </h1>
                            <img src={blinx_logo} alt='Blinx logo' />
                        </div>
                        <h1> Questions </h1>
                    </div>
                </div>
            </div>

            <div className="blinx__faq-content" ref={scroll_ref}>
                <div className="blinx__faq-content_fields">
                    { faqs.map((faq, index) => (
                        <Accordion 
                            key={index}
                            active={active}
                            faq={faq} 
                            index={index}
                            setActive={setActive}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQ;