import React from 'react';
import './accordion.css';

import { FiPlus } from "react-icons/fi";

const Accordion = ({ active, faq, index, setActive }) => {

    const handleClick = () => {
        if(index === active) {
            return setActive(-1);
        }
        setActive(index);
    }
    return (
        <div className={`blinx__accordion ${ active === index ? "-active" : ""}`}>
            <div className="blinx__accordion-header">
                <FiPlus onClick={handleClick} />
                <h3> { faq.question } </h3>
            </div>
            { active === index && <div className="blinx__accordion-body scale-up-center">
                <p> { faq.answer } </p>
            </div> }
        </div>
    )
}

export default Accordion;