import React from "react";
import "./features.css";

import img1 from "../../assets/view1.svg";
import img2 from "../../assets/view2.svg";
import img3 from "../../assets/view3.svg";

import Feature from "../../components/features/Feature";

const features = [
    {
        id: 1,
        bg: "#1AA6B7",
        color: "#fff",
        img_url: img1,
        title: "Fast payments",
        subtitle: "Move money across town, across Africa or across continents hassle-free."
    },
    {
        id: 2,
        bg: "#E2E8F0",
        color: "#000",
        img_url: img2,
        title: "Automate bills",
        subtitle: "Always be at the top of your bills and payments by automating them"
    },
    {
        id: 3,
        bg: "#E7FCFF",
        color: "#000",
        img_url: img3,
        title: "Budget expenses",
        subtitle: "Supercharge your finance management with our budgeting solution."
    },
]

const Features = ({ features_ref }) => {
    return (
        <div className="blinx__features">
            <div className="blinx__features-container">
                <div className="blinx__features-header">
                    <h3> Why use blinx ? </h3>
                </div>

                <div className="blinx__features-body" ref={features_ref}>
                    { features.map(feature => (
                        <Feature 
                            key={feature.id}
                            color={feature.color}
                            bgColor={feature.bg}
                            img={feature.img_url}
                            subtitle={feature.subtitle}
                            title={feature.title}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Features;