import React from "react";
import "./tools.css";

import logo from "../../assets/icon-bg.svg";
import tool1 from "../../assets/tool-1.svg"
import tool2 from "../../assets/tool-2.svg"
import tool3 from "../../assets/tool-3.svg"
import tool4 from "../../assets/tool-4.svg"

const BuiltWith = () => {
    return (
        <div className="blinx__builtwith">
            <div className="blinx__builtwith-container">
                <div className="blinx__builtwith-row d-flex item-center">
                    <div className="blinx__builtwith-col -left">
                        <div className="blinx__builtwith-tools -left d-flex flex-column gap-2 item-center">
                            <div className="blinx__builtwith-tool d-flex item-center justify-center">
                                <span> Custom Integration </span>
                            </div>
                            <div className="blinx__builtwith-tool d-flex item-center justify-center">
                                <img src={tool1} alt="" />
                            </div>
                            <div className="blinx__builtwith-tool d-flex item-center justify-center">
                                <img src={tool4} alt="" />
                            </div>
                        </div>

                        <div className="blinx__builtwith-tools -right d-flex flex-column gap-2 item-center">
                            <div className="blinx__builtwith-tool d-flex item-center justify-center">
                                <img src={tool2} alt="One-id" />
                            </div>
                            <div className="blinx__builtwith-tool d-flex item-center justify-center">
                                <img src={tool3} alt="" />
                            </div>
                            <div className="blinx__builtwith-tool d-flex item-center justify-center">
                                <span> Custom Integration </span>
                            </div>
                        </div>
                    </div>

                    <div className="blinx__builtwith-col -right">
                        <div className="-col_heading">
                            <h2> Secure <span className="-top"> and </span>  </h2>
                            <div className="d-flex item-center gap-1">
                                <div className="d-flex item-center justify-center">
                                    <img src={logo} alt="Blinx Logo" />
                                </div>
                                <h2> <span className="-bottom"> and </span>  private </h2>
                            </div>
                        </div>
                        <p> We'll keep you safe. Built from the ground up using the best in class security protocols available in the market. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuiltWith;