import React, { useEffect, useState } from 'react';
import './cta.css';

import logo_icon from '../../assets/icon.svg';
import cta_mockup from "../../assets/cta-mockup.svg";

// utils
import api from '../../utils/api';
import { collection, where,  query, getDocs } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { toast } from 'react-hot-toast';

const CTA = ({ waitlist_ref }) => {
    const [ email, set_email ] = useState('');
    const [ form_submitted, set_form_submitted ] = useState(false);
    const [ toggle_form, set_toggle_form ] = useState(false);
    const [ loading, set_loading ] = useState(false);
    const [ error, set_error ] = useState(false);

    const q = query(collection(db, 'waitlists'), where("email", "==", email));

    const handle_submit = async (e) => {
        e.preventDefault();
        set_loading(true);
        try {
            const snapshot = await getDocs(q);
            if(snapshot.docs.length > 0){
                toast.success("You are on the BlinxHQ waitlist, you will be notified when the product is live.");
            } 
            else {
                await api.submit({email})
                toast.success("You’ve been added to the BlinxHQ waitlist, you will be notified when the product is live.");
            }
            
            set_form_submitted(true);
            set_toggle_form(false);
            set_email('');
        }
        catch(err){
            console.log(err);
            set_error(true);
            set_form_submitted(false);
            set_email("");
            toast.error("Failed to join waitlist - Please try again!");
        } 
        finally {
            set_loading(false);
        }
    }

    useEffect(()=> {
        const handle_scroll = () => {
            set_toggle_form(false);
        }

        window.addEventListener("scroll", (e) => {
            handle_scroll()
        });
        window.removeEventListener("scroll", (e) => {
            handle_scroll();
        });
    }, [])

    return (
        <div className="blinx__cta" ref={waitlist_ref}>
            <div className="blinx__cta-container">
                <div className="blinx__cta-content">
                    <div className="blinx__cta-text">
                        <h3> Stay in the loop! </h3>
                    </div>
                    <div className="blinx__cta-group d-flex item-center gap-1 justify-center">
                        <div className="blinx__cta-logo">
                            <img src={logo_icon} alt="blinx logo" />
                        </div>
                        <h3> sign up now </h3>
                    </div>
                    <div className="blinx__cta-waitlist">
                        { !toggle_form && (
                            <button
                                className="blinx__cta-btn"
                                onClick={()=> set_toggle_form(true)}
                                disabled={form_submitted ? true: false}
                            >
                                <span> { form_submitted ? "Thank You!!!" : error ?  "Try Again" : "Join our waitlist" } </span> 
                            </button> 
                        )}

                        { toggle_form && (
                            <div className="blinx__cta-waitlist_form scale_up_hor_center">
                                <form onSubmit={handle_submit}>
                                    <input 
                                        disabled={loading}
                                        name="email"
                                        onChange={(e)=> set_email(e.target.value)}
                                        placeholder="E-mail" 
                                        required={true} 
                                        type="email"
                                        value={email} 
                                    />
                                    <button 
                                        type="submit" 
                                        onSubmit={handle_submit}
                                        disabled={loading}
                                    > 
                                        <span> { loading ? "Subscribing..." : "Subscribe" } </span>
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>

                    <div className="blinx__cta-app_img">
                        <div className="d-flex item-center justify-center">
                            <img src={cta_mockup} alt="Blinx App" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTA;