import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './navbar.css';

// images
import logo from '../../assets/full-logo.svg';
import logo2 from '../../assets/logo-alt.svg';



export default function Navbar({ handle_click }){
    const { hash, pathname } = useLocation();
    const [ open , set_open ] = useState(false);

    const handle_button_click = () => {
        if(open) {
            set_open(false);
            handle_click();
        }
        else {
            handle_click();
        }
    }

    useEffect(()=> {
        window.addEventListener("resize", () => {
            if(window.innerWidth > 1023){
                set_open(false);
            }
        });
    }, []);

    
    useEffect(()=> {
        let previous_pos = window.pageYOffset;
        const handle_scroll = () => {
            const navbar = document.querySelector(".blinx__navbar");
            let current_pos = window.pageYOffset;
    
            // FIXED
            if(window.pageYOffset > 0) {
                navbar.classList.add("-fixed");
            }
            else {
                navbar.classList.remove("-fixed");
            }
    
            // VISIBILITY
            if (previous_pos > current_pos) {
                navbar.classList.add("-visible")
            } else {
                navbar.classList.remove("-visible")
            }
            previous_pos = current_pos;
        }

        window.addEventListener("scroll", (e) => {
            handle_scroll();
        })
        window.removeEventListener("scroll", (e) => {
            handle_scroll();
        })
    }, [])

    return (
        <nav className={`blinx__navbar ${open ? "-open" : ""}`} id="nav">
            <div className={`blinx__navbar-bg`}></div>
            <div className="blinx__navbar-fill"></div>
            <div className="blinx__navbar-toggle">
                <button className="blinx__navbar-toggle_menu" onClick={()=> set_open(!open)}>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div className="blinx__navbar-content">
                <div className="blinx__navbar-container">
                    <div className="blinx__navbar-container_row d-flex item-center justify-between">
                        <div className="blinx__navbar-container_col left">
                            <div className="blinx__navbar-logo -static">
                                <Link to="/">
                                    <img src={!open ? logo : logo2} alt="Blinxhq" className="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="blinx__navbar-container_col center">
                            <div className="blinx__navbar-nav">
                                <a href="/#why-blinx" className={`blinx__navbar-navItem ${hash} === "#why" ? "-active" : "" }`} onClick={()=> set_open(false)}>
                                    <span> Why blinx </span>
                                </a>
                                <a href="/#about" className={`blinx__navbar-navItem ${hash === "#about" ? "-active" : "" }`} onClick={()=> set_open(false)}>
                                    <span> About us </span>
                                </a>
                                <Link to="/faqs" className={`blinx__navbar-navItem ${pathname === "/faqs" ? "-active" : "" }`} onClick={()=> set_open(false)}>
                                    <span> FAQ </span>
                                </Link>
                            </div>
                        </div>
                        <div className="blinx__navbar-container_col right">
                            <div className="blinx__navbar-actions">
                                <button 
                                    className="blinx__navbar-btn" 
                                    id="navbar-btn"
                                    onClick={handle_button_click}
                                >
                                    <span> Join Our Waitlist </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}