import React from "react";
import "./particles.css";

import card from '../../assets/card.svg';
import logo from '../../assets/logo.svg';

const Particles = () => {
    return (
        <div className="blinx__particles w-100 h-100">
            <div className="blinx__particles-container d-flex w-100 h-100">
                {/* Cards and logos */}
                <div className="blinx__hero-particle -item -v1 -logo" style={{"--i":18}}>
                    <img src={logo} alt="Blinx Logo" loading="lazy" />
                </div>
                <div className="blinx__hero-particle -item -v2 -card" style={{"--i":27}}>
                    <img src={card} alt="Blinx Card" loading="lazy" />
                </div>
                <div className="blinx__hero-particle -item -v3 -logo" style={{"--i":22}}>
                    <img src={logo} alt="Blinx Logo" loading="lazy" />
                </div>
                <div className="blinx__hero-particle -item -v4 -card" style={{"--i":19}}>
                    <img src={card} alt="Blinx Card" loading="lazy" />
                </div>

                {/* Dots */}
                <div className="blinx__hero-particle -item -v5 -dot" style={{"--i":8}}></div>
                <div className="blinx__hero-particle -item -v6 -dot" style={{"--i":12}}></div>
                <div className="blinx__hero-particle -item -v7 -dot" style={{"--i":17}}></div>
                <div className="blinx__hero-particle -item -v8 -dot" style={{"--i":14}}></div>
                <div className="blinx__hero-particle -item -v9 -dot" style={{"--i":9}}></div>
                <div className="blinx__hero-particle -item -v10 -dot" style={{"--i":13}}></div>
                <div className="blinx__hero-particle -item -v11 -dot" style={{"--i":15}}></div>
                <div className="blinx__hero-particle -item -item12 -dot" style={{"--i":19}}></div>
            </div>
        </div>
    )
}

export default Particles;