import React from "react";
import "./oppurtunity.css";

const Oppurtunity = () => {
    return (
        <div className="blinx__oppurtunity">
            <div className="blinx__oppurtunity-container">
                <div className="blinx__oppurtunity-header d-flex flex-column item-center justify-center">
                    <div className="d-flex item-center gap-0 -top">
                        <p> One <br /> App </p>
                        <h2> Endless </h2>
                    </div>
                    <div className="-bottom">
                        <h2> Oppurtunities </h2>
                    </div>
                </div>

                <div className="blinx__oppurtunity-body">
                    <div className="blinx__oppurtunity-row d-flex item-center justify-between gap-2">
                        <div className="blinx__oppurtunity-col -left">
                            <p> Bill Payments </p>
                        </div>
                        <div className="blinx__oppurtunity-col -right">
                            <p> Funds Transfer </p>
                        </div>
                    </div>

                    <div className="blinx__oppurtunity-row d-flex item-center justify-between gap-2">
                        <div className="blinx__oppurtunity-col -left">
                            <p> Payments Automation </p>
                        </div>
                        <div className="blinx__oppurtunity-col -right">
                            <p> Finance Budgeting </p>
                        </div>
                    </div>

                    <div className="blinx__oppurtunity-row d-flex item-center justify-between gap-2">
                        <div className="blinx__oppurtunity-col -left">
                            <p> Financial Analysis </p>
                        </div>
                        <div className="blinx__oppurtunity-col -right">
                            <p> Saving Plans </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Oppurtunity