import React from 'react';
import { Routes, Route  } from 'react-router-dom';
import Layout from './containers/layout/Layout';
import "./app.css";

import Homepage from './routes/homepage';
import Faq from './routes/faq';

const App = () => {
  return (
    <Routes>
      <Route path="" element={<Layout />}>
        <Route index path="/" element={<Homepage />} />
        <Route path="/faqs" element={<Faq />} />
      </Route>
    </Routes>
  )
}

export default App;